import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import BigNumber from "bignumber.js";

export function calculateRating(segments) {
  const ratings = segments
    .map((item) => {
      return {
        finalStarRating: item?.segmentAssessment?.finalStarRating,
        fromSLK: item?.fromSLK,
        toSLK: item?.toSLK,
      };
    })
    .filter((rating) => rating.finalStarRating != null);

  const stars = [
    {
      key: 0,
      value: ratings
        .filter((s) => s.finalStarRating === 0)
        .reduce(
          (partial, s) =>
            new BigNumber(partial)
              .plus(new BigNumber(s.toSLK).minus(s.fromSLK).abs())
              .toNumber(),
          0
        ),
    },
    {
      key: 1,
      value: ratings
        .filter((s) => s.finalStarRating === 1)
        .reduce(
          (partial, s) =>
            new BigNumber(partial)
              .plus(new BigNumber(s.toSLK).minus(s.fromSLK).abs())
              .toNumber(),
          0
        ),
    },
    {
      key: 3,
      value: ratings
        .filter((s) => s.finalStarRating === 3)
        .reduce(
          (partial, s) =>
            new BigNumber(partial)
              .plus(new BigNumber(s.toSLK).minus(s.fromSLK).abs())
              .toNumber(),
          0
        ),
    },
    {
      key: 5,
      value: ratings
        .filter((s) => s.finalStarRating === 5)
        .reduce(
          (partial, s) =>
            new BigNumber(partial)
              .plus(new BigNumber(s.toSLK).minus(s.fromSLK).abs())
              .toNumber(),
          0
        ),
    },
  ].sort((a, b) => b.value - a.value);

  return ratings.length > 0 ? stars[0].key : 0;
}

export function renderStars(starRating, theme, isMobile) {
  return Array.from({ length: 5 }, (_, index) => {
    const isFilled = index < Math.floor(starRating);
    const IconComponent = isFilled ? StarIcon : StarBorderIcon;

    return (
      <IconComponent
        key={index}
        style={{
          marginTop: "4px",
          fontSize: isMobile ? "30px" : "70px",
          color: theme.palette.common.red,
        }}
      />
    );
  });
}

export function addStarRatings(obj) {
  for (let key in obj) {
    if (typeof obj[key] === "string") {
      let value = obj[key].split(" Star")[0];
      let rating;

      switch (value) {
        case "<1":
          rating = 0;
          break;
        case "1":
          rating = 1;
          break;
        case "3":
          rating = 3;
          break;
        case "5":
          rating = 5;
          break;
        default:
          rating = null;
          break;
      }

      obj[key + "Rating"] = rating;
    }
  }
  return obj;
}

export function calculateFinalRating(ratings) {
  const starRatings = ratings.map((r) => r.value);

  const stars = [
    {
      key: 0,
      value: starRatings.filter((s) => s === 0).length,
    },
    {
      key: 1,
      value: starRatings.filter((s) => s === 1).length,
    },
    {
      key: 3,
      value: starRatings.filter((s) => s === 3).length,
    },
    {
      key: 5,
      value: starRatings.filter((s) => s === 5).length,
    },
  ].sort((a, b) => b.value - a.value);

  return {
    value: stars[0].key,
    name: "Final Star Rating",
  };
}

export function tallyRatings(ratings) {
  const tally = { lessThanOneStar: 0, 1: 0, 3: 0, 5: 0 };

  Object.values(ratings).forEach((rating) => {
    rating.hasLessThanSign ? tally.lessThanOneStar++ : tally[rating.value]++;
  });
  return {
    lessthanOneStarCount: tally.lessThanOneStar,
    oneStarCount: tally[1],
    threeStarCount: tally[3],
    fiveStarCount: tally[5],
  };
}

export function extractRating(data) {
  if (!data.segmentAssessment) return;
  const result = [];
  try {
    Object.entries(data.segmentAssessment).forEach(([key, str]) => {
      if (key === "segmentId" || key.endsWith("Rating")) return;
      if (str && isNaN(str) && str.includes("Star")) {
        let parts = str.split(" ");
        let rating = {
          value: data.segmentAssessment[key + "Rating"],
          hasLessThanSign: parts[0].includes("<"),
          name: convertKeyToName(key),
        };
        result.push(rating);
      }
    });
  } catch (error) {
    console.log(error);
  }

  return result;
}

//converts a key such as "roadWidth" to "Road Width"
function convertKeyToName(key) {
  let name = key.charAt(0).toUpperCase() + key.slice(1);
  return name.replace(/([A-Z])/g, " $1").trim();
}
